/* eslint-disable react/destructuring-assignment */
import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import * as React from "react";
import { components, OptionProps as ReactSelectOptionProps } from "react-select";
import * as css from "../styles.scss";
import { SelectOption } from "../types";

export type OptionProps = ReactSelectOptionProps<SelectOption, false>;

const Option: React.FC<OptionProps> = (props) => {
  if (props.data.description) {
    return (
      <components.Option {...props}>
        <div className={css.optionWithDescriptionWrapper}>
          <span>{props.data.label}</span>
          <span>{props.data.description}</span>
        </div>
      </components.Option>
    );
  }

  if (props.data.multiIconLabel) {
    return (
      <components.Option {...props}>
        <div className={css.multiWrapper}>
          <div className={css.multiIconsWrapper}>
            {props.data.multiIconLabel.map(
              (element: { icon: IconComponent; label: number }, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <Icon InlineIcon={element.icon} extraClasses={css.multiIcon} />
                  <span>{element.label}</span>
                </div>
              )
            )}
          </div>
          <div className={css.multiInfoWrapper}>
            <span>{props.data.label}</span>
          </div>
        </div>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <div className={css.optionWrapper}>
        {props.data.icon && <span className={css.icon}>{props.data.icon}</span>}
        <span className={css.optionLabel}>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

export default Option;
