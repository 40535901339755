import { __rest, __assign } from '../../../node_modules/tslib/tslib.es6.js';

/** Creates a 'day' object for the calendar based on a given Date class instance. */
function buildCalendarDay(_a) {
  var date = _a.date,
    disabled = _a.disabled,
    prev = _a.prev,
    next = _a.next,
    rest = __rest(_a, ["date", "disabled", "prev", "next"]);
  return __assign({
    weekday: date.getDay(),
    number: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    date: date,
    disabled: disabled,
    prev: prev,
    next: next
  }, rest);
}

export { buildCalendarDay };
