var isSameMonth = function (a, b) {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth();
};
var isSameDate = function (a, b) {
  return isSameMonth(a, b) && a.getDate() === b.getDate();
};
var isInDateRange = function (date, startDate, endDate) {
  return date.getTime() < endDate.getTime() && date.getTime() > startDate.getTime();
};
/** Normalizes time for date given to be a default 00:00:00 */
var trimTimeFromDate = function (date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export { isInDateRange, isSameDate, isSameMonth, trimTimeFromDate };
