import { SelectOption } from "../../../../helper/reactSelect/types";
import formatMessage from "../../../translationProvider/helpers/formatMessage/formatMessage";

export default (value: string, options: Array<SelectOption>) => {
  const option = options.find((opt) => opt.value === value);

  if (option) {
    return option.label;
  }

  return formatMessage("general.notAvailable");
};
