import ReactUtils from '../../../packages/react-utils/react-utils.js';
import 'react';
import '../../../packages/props-utils/prop-types.js';

var headerUserWidgetSubMenuValidator = function () {
  return function (_a) {
    var children = _a.children,
      subMenu = _a.subMenu;
    if (subMenu) {
      var emptyStringChildren = typeof children === 'string' && !children.trim();
      var emptyObjectChildren = children && typeof children === 'object' && Object.keys(children).length === 0;
      if (!children || emptyStringChildren || emptyObjectChildren) {
        return new Error('A \'children\' text is required when a subMenu is present at \'HeaderUserWidget\' component.');
      }
      if (!ReactUtils.isOfType(subMenu, 'HeaderSubNavigation')) {
        return new Error("The 'subMenu' prop at 'HeaderUserWidget' only accepts a\n        'HeaderSubNavigation' component but received '".concat(ReactUtils.getElementName(subMenu), "'."));
      }
    }
    return null;
  };
};

export { headerUserWidgetSubMenuValidator };
