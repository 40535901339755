import { __spreadArray } from '../../../node_modules/tslib/tslib.es6.js';
import { buildCalendarDay } from './build-calendar-day.js';

/**
 * Calculates how many days from the previous month are needed so the calendar matches the layout.
 * Then returns an array with padding days from the previous month in the correct order.
 */
function buildPrevMonthDays(year, month, options) {
  // first day of week of this month
  var firstWeekDay = new Date(year, month, 1).getDay();
  var startPadLength = 0;
  if (options.offset > firstWeekDay) {
    startPadLength = 7 - firstWeekDay - options.offset;
  }
  if (options.offset < firstWeekDay) {
    startPadLength = firstWeekDay - options.offset;
  }
  return startPadLength > 0 ? new Array(startPadLength).fill(null).map(function (_, i) {
    // Time travel in JS. Where `days <=0` go back in time.
    // e.g.:
    //  - new Date(2020, 5, 0)  -> new Date(2019, 4, 31)
    //  - new Date(2020, 5, -1) -> new Date(2019, 4, 30)
    var date = new Date(year, month, 0 - i);
    return buildCalendarDay({
      date: date,
      disabled: options.disableDayFn(date),
      prev: true // adds prev flag
    });
  }).reverse() : [];
}
/** Returns an array of days from the current month. */
function buildMonthDays(year, month, options) {
  // Another prime example of time traveling.
  // Advancing 1 month to get day 0 = last day of current month.
  return new Array(new Date(year, month + 1, 0).getDate()).fill(null).map(function (_, i) {
    var date = new Date(year, month, i + 1);
    return buildCalendarDay({
      date: date,
      disabled: options.disableDayFn(date)
    });
  });
}
/**
 * Calculates how many days from the next month are needed so the calendar matches the layout.
 * Then returns an array with padding days from the next month.
 */
function buildNextMonthDays(totalCalendarDays, year, month, options) {
  var daysInLastWeek = totalCalendarDays % 7;
  // if the last week is full, no padding is needed
  var padEndLength = daysInLastWeek === 0 || daysInLastWeek === 7 ? 0 : 7 - daysInLastWeek;
  return padEndLength ? new Array(Math.abs(padEndLength)).fill(null).map(function (_, i) {
    // ensure correct next month/year depending on current month
    var nextOrCurrentMonth = month <= 10 ? month + 1 : 0;
    var nextOrCurrentYear = month <= 10 ? year : year + 1;
    var date = new Date(nextOrCurrentYear, nextOrCurrentMonth, 1 + i);
    return buildCalendarDay({
      date: date,
      disabled: options.disableDayFn(date),
      next: true
    });
  }) : [];
}
/**
 * Creates an array of arrays of length 7 representing the smallest required amount of full weeks to
 * display a month.
 */
function buildCalendarMonth(year, month, options) {
  var startPad = buildPrevMonthDays(year, month, options);
  var days = buildMonthDays(year, month, options);
  var endPad = buildNextMonthDays(startPad.length + days.length, year, month, options);
  var mergedDays = __spreadArray(__spreadArray(__spreadArray([], startPad, true), days, true), endPad, true);
  // splits days in weeks
  var weeks = [];
  for (var i = 0; i < mergedDays.length; i += 7) {
    weeks.push(mergedDays.slice(i, i + 7));
  }
  return weeks;
}

export { buildCalendarMonth, buildMonthDays, buildNextMonthDays, buildPrevMonthDays };
