import { trimTimeFromDate } from '../calendar-utils.js';
import { buildCalendarMonth } from './build-calendar-month.js';

/**
 * Creates an object which keys 'months' and 'years' containing all months required by the
 * calendar component.
 */
function calendarFactory(start, end, options) {
  var _a, _b;
  // passing `new Date()` as prop value in here adds current time that breaks date comparisons,
  // making sure all the received dates from props have default time 00:00:00
  var startDate = trimTimeFromDate(start);
  var endDate = trimTimeFromDate(end);
  // default function to disable dates outside of calendar range
  var defaultDisableDayFn = function (date) {
    return date < startDate || date > endDate;
  };
  // initialize years
  var months = [];
  var years = new Set([]);
  // maximum amount of required months by provided years
  var monthsLength = (end.getFullYear() - start.getFullYear() + 1) * 12;
  // for each month
  for (var i = 0; i < monthsLength; i += 1) {
    // check if month is actually needed, if not, skip
    if (i % 12 < start.getMonth() && i < 11 || i % 12 > end.getMonth() && i >= monthsLength - 11) {
      continue; // eslint-disable-line no-continue
    }
    // current year
    var year = start.getFullYear() + Math.floor(i / 12);
    years.add(year);
    // for each month add an entry
    months.push({
      year: year,
      month: i % 12,
      // weeks are generated by month factory
      weeks: buildCalendarMonth(year, i % 12, {
        disableDayFn: (_a = options === null || options === void 0 ? void 0 : options.disableDayFn) !== null && _a !== void 0 ? _a : defaultDisableDayFn,
        offset: (_b = options === null || options === void 0 ? void 0 : options.offset) !== null && _b !== void 0 ? _b : 1
      })
    });
  }
  return {
    months: months,
    years: years
  };
}

export { calendarFactory };
