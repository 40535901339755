import { IconComponent } from "@flixbus/honeycomb-icons-react";

export type SelectOption = {
  value: string;
  label: string;
  icon?: JSX.Element;
  description?: string;
  isDisabled?: boolean;
  multiIconLabel?: Array<{ icon: IconComponent; label: number }>;
};
