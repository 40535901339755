@use "variables" as *;

@mixin option-with-description {
  display: grid;

  :first-child {
    font-weight: 400;
  }

  > span {
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

$label-column-width: 75px;

.optionWithDescriptionWrapper {
  grid-template-columns: $label-column-width auto;
  @include option-with-description;
}

.singleValueWithDescriptionWrapper {
  grid-template-columns: $label-column-width 205px;
  @include option-with-description;
}

.multiWrapper {
  color: $content-secondary-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.multiIconsWrapper {
  display: flex;
  column-gap: $spacing-2;
}

.multiIcon {
  margin-right: $spacing-half;
}

.multiInfoWrapper {
  display: flex;
  column-gap: $spacing-half;
  font-size: 14px;
}

.optionWrapper {
  display: flex;
  align-items: center;
}

.icon {
  padding-right: $spacing-2;
  display: flex;
}

.singleValueLabel,
.optionLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
