@use "variables" as *;

.noOption {
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
  padding: $spacing-1 $spacing-2;
  cursor: pointer;
  &:hover {
    background-color: $grayscale-10-color;
  }
}

.icon {
  float: right;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: $spacing-1;
  padding-bottom: $spacing-1;
}
